/**
 * Ideatica Core Cloud v.1.4.0
 * Abril 2025
*/

'use strict';

class Core {


  static forms()
  {
  }

  static actions()
  {
  }

  static select2()
  {
      const select2 = $('.select2');
      if (select2.length) {
        select2.each(function () {
          var $this = $(this);
          $this.wrap('<div class="position-relative"></div>').select2({
            placeholder: $this.attr('placeholder'),
            dropdownParent: $this.parent()
          });
        });
      }

      // Select2 Icons
      const select2Icons = $('.select2-icons');
      if (select2Icons.length) {
        // custom template to render icons
        function renderIcons(option) {
          if (!option.id) {
            return option.text;
          }
          var $icon = "<i class='" + $(option.element).data('icon') + " me-2'></i>" + option.text;
          return $icon;
        }
        select2Icons.each(function () {
          var $this = $(this);
          $this.wrap('<div class="position-relative"></div>').select2({
            dropdownParent: $this.parent(),
            templateResult: renderIcons,
            templateSelection: renderIcons,
            placeholder: 'Seleccionar ...',
            escapeMarkup: function (es) {
              return es;
            }
          });
        });
      }

      // Select2 Color
      const select2Color = $('.select2-color');
      if (select2Color.length) {
        // custom template to render icons
        function renderColor(option) {
          if (!option.id) {
            return option.text;
          }
          var $icon = "<span class='" + $(option.element).data('color') + "'>&nbsp;</span>";
          return $icon;
        }

        select2Color.each(function () {
          var $this = $(this);
          $this.wrap('<div class="position-relative"></div>').select2({
            dropdownParent: $this.parent(),
            templateResult: renderColor,
            templateSelection: renderColor,
            placeholder: 'Seleccionar color ...',
            escapeMarkup: function (es) {
              return es;
            }
          });
        });
      }

      const selectUser = $('.select2-user');
      if (selectUser.length) {
        function renderAvatar(option) {
            if (!option.id) {
              return option.text;
            }
            var optionEle =
              '<div class="d-flex align-items-center">' +
              '<div class="avatar avatar-xs me-2 d-flex">' +
              '<img src="' + $(option.element).data('image') + '" class="rounded-circle">' +
              '</div>' +
              '<div class="name">' +
              $(option.element).data('name') +
              '</div>' +
              '</div>';
            return optionEle;
        }

        selectUser.each(function () {
          var $this = $(this);
          $this.wrap('<div class="position-relative"></div>').select2({
            dropdownParent: $this.parent(),
            templateResult: renderAvatar,
            templateSelection: renderAvatar,
            placeholder: 'Selecciona un usuario ...',
            escapeMarkup: function (es) {
              return es;
            }
          });
        });
      }

  }

  static start() {
    this.forms();
    this.actions();
    this.select2();
  }

};

Core.start();
  